import { action, computed } from 'mobx'

import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import DesktopDeliveryViewStore from '~/client/src/desktop/views/Deliveries/Deliveries.store'
import {
  BasicDataKeys,
  ILWFCCategory,
  ILWFCColumn,
  ILWFCRow,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import {
  deliveryStatusToSortIndexMap,
  formatStatusToDisplay,
} from '~/client/src/shared/constants/DeliveryStatus'
import { CancelationReasonNames } from '~/client/src/shared/enums/CancelationReason'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import {
  deliveryFilterTypes,
  extendedDeliveryFilterTypes,
} from '~/client/src/shared/enums/DeliveryFilterType'
import DeliveryGroupingOption from '~/client/src/shared/enums/DeliveryGroupingOption'
import { DeliveryStatusListView } from '~/client/src/shared/enums/DeliveryStatusListView'
import { InspectionStates } from '~/client/src/shared/enums/InspectionState'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'
import Material from '~/client/src/shared/models/Material'
import User from '~/client/src/shared/models/User'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import {
  ACTIVATE_PROJECT,
  DELETE_DELIVERIES,
  EDIT_BULK_DELIVERIES,
  UPDATE_BULK_DELIVERIES_STATUS,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveryAssignmentsStore from '~/client/src/shared/stores/domain/DeliveryAssignments.store'
import DeliveryFollowingsStore from '~/client/src/shared/stores/domain/DeliveryFollowings.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import {
  CATEGORY_ROW_HEIGHT,
  DEFAULT_ID_KEY,
  ITreeNodeObj,
} from '~/client/src/shared/stores/ui/BaseList.store'
import BaseMultiBandListStore from '~/client/src/shared/stores/ui/BaseMultiBandList.store'
import ProjectDateStore, {
  millisecondsToTime,
} from '~/client/src/shared/stores/ui/ProjectDate.store'
import IDeliveryAttributeDto from '~/client/src/shared/types/IDeliveryAttributeDto'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import {
  commonAttributeMapper,
  commonById,
  commonByIds,
  commonIdMapper,
  groupingCommonMapper,
} from '~/client/src/shared/utils/mappers'
import { NOOP } from '~/client/src/shared/utils/noop'
import {
  EMPTY_DATE_VALUE,
  EMPTY_STRING,
  MULTIPLICATION,
  NO_SPECIFIED,
  NO_VALUE,
} from '~/client/src/shared/utils/usefulStrings'
import { areArraysEqual } from '~/client/src/shared/utils/util'

// localization: translated

enum SpecificDataKeys {
  STATUS = 'status',
  BOOKING_ID = 'booking-id',
  MATERIALS_ITEMS_COUNT = 'materials-items-count',
}

const excludedFilters = extendedDeliveryFilterTypes.filter(
  ft => !deliveryFilterTypes.includes(ft),
)

export interface IDateCell {
  dates: string[]
  isActive?: boolean
}

export const DataKeys = { ...SpecificDataKeys, ...FieldIds, ...BasicDataKeys }

export const BOOKING_SECTION_NAME = 'booking'
export const DURATION_SECTION_NAME = 'duration'
export const LOCATIONS_SECTION_NAME = 'locations'
export const EQUIPMENT_SECTION_NAME = 'equipment'
export const MATERIALS_SECTION_NAME = 'materials'
export const VENDOR_SECTION_NAME = 'vendor'

const SITE_BAND = 'Site'

const lbsBands: string[] = [
  SITE_BAND,
  DeliveryGroupingOption[FieldIds.BUILDING],
  DeliveryGroupingOption[FieldIds.ZONE],
  DeliveryGroupingOption[FieldIds.LEVEL],
  DeliveryGroupingOption[FieldIds.AREA],
]

const statusBands: string[] = [
  FieldIds.STATUS,
  DeliveryGroupingOption[FieldIds.CANCELATION_REASON],
  DeliveryGroupingOption[FieldIds.INSPECTION_SECTION],
]

export default class DeliveriesListStore extends BaseMultiBandListStore<Delivery> {
  private clearPostEventCallback: () => void = NOOP
  private initDeliveryId: string = ''

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly deliveryAssignmentsStore: DeliveryAssignmentsStore,
    private readonly deliveryFollowingsStore: DeliveryFollowingsStore,
    private readonly desktopDeliveryViewStore: DesktopDeliveryViewStore,
    private readonly materialCategoryStore: MaterialCategoryStore,
    private readonly companiesStore: CompaniesStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly userProjectsStore: UserProjectsStore,
  ) {
    super(
      eventsStore.appState.deliveryFilters,
      () => desktopDeliveryViewStore.availableDeliveries,
      [],
      DEFAULT_ID_KEY,
    )
  }

  @computed
  public get rows(): ILWFCRow[] {
    const { selectedDeliveryBandsOption } = this.eventsStore.appState.filters

    const shouldUseLbsBands = areArraysEqual(
      selectedDeliveryBandsOption.bands,
      [DeliveryGroupingOption.location],
    )

    const shouldUseStatusBands = areArraysEqual(
      selectedDeliveryBandsOption.bands,
      [DeliveryGroupingOption.status],
    )

    const shouldUseDateBands = areArraysEqual(
      selectedDeliveryBandsOption.bands,
      [DeliveryGroupingOption[FieldIds.DATE]],
    )

    const customBands =
      (shouldUseLbsBands && lbsBands) ||
      (shouldUseStatusBands && statusBands) ||
      []

    const sortingFunc = this.sortingFunc(
      shouldUseStatusBands,
      shouldUseLbsBands,
      shouldUseDateBands,
    )

    return this.toBandTreeNodeRows(
      customBands.length ? customBands : selectedDeliveryBandsOption.bands,
      null,
      '',
      0,
      false,
      sortingFunc,
    )
  }

  public columnsWidthState = new Map<string, number>([
    [DataKeys.CHECKBOX, 40],
    [DataKeys.BOOKING_ID, 220],
    [DataKeys.STATUS, 120],
    [DataKeys.COMPANY, 150],
    [DataKeys.ON_SITE_CONTACTS, 200],
    [DataKeys.DATE, 365],
    [DataKeys.DURATION, 85],
    [DataKeys.OFFLOADING_EQUIPMENT, 250],
    [DataKeys.NUMBER_OF_EQUIPMENT_PICKS, 120],
    [DataKeys.MATERIALS_SECTION, 550],
    [DataKeys.MATERIALS_ITEMS_COUNT, 100],
    [DataKeys.VENDOR, 180],
    [DataKeys.TRUCK_SIZE, 170],
    [DataKeys.TRUCK_LICENSE_PLATE, 170],
    [DataKeys.DRIVER_PHONE_NUMBERS, 180],
  ])

  @computed
  public get columns(): ILWFCColumn[] {
    const {
      delivery: { hiddenFields },
    } = this.appState
    const { getClientTimezoneOffsetAsString } = this.projectDateStore
    const { getFieldName } = this.desktopDeliveryViewStore

    const columns = [
      {
        dataKey: DataKeys.CHECKBOX,
        sectionName: BOOKING_SECTION_NAME,
      },
      {
        label: Localization.translator.bookingId,
        dataKey: DataKeys.BOOKING_ID,
        sectionName: BOOKING_SECTION_NAME,
        isMonospace: true,
      },
      {
        label: Localization.translator.status,
        dataKey: DataKeys.STATUS,
        sectionName: BOOKING_SECTION_NAME,
      },
      ...(!hiddenFields[DataKeys.COMPANY]
        ? [
            {
              label: Localization.translator.responsibleCompany,
              dataKey: DataKeys.COMPANY,
              sectionName: BOOKING_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.ON_SITE_CONTACTS]
        ? [
            {
              label: Localization.translator.responsibleContact,
              dataKey: DataKeys.ON_SITE_CONTACTS,
              sectionName: BOOKING_SECTION_NAME,
            },
          ]
        : []),
      {
        label: Localization.translator.bookingDateEnTimeOffset(
          getClientTimezoneOffsetAsString(),
        ),
        dataKey: DataKeys.DATE,
        sectionName: BOOKING_SECTION_NAME,
        isMonospace: true,
      },
      {
        label: Localization.translator.durHmm_durationShort,
        dataKey: DataKeys.DURATION,
        isMonospace: true,
        sectionName: DURATION_SECTION_NAME,
      },
      ...(!hiddenFields[DataKeys.BUILDING]
        ? [
            {
              label: getFieldName(DataKeys.BUILDING),
              dataKey: DataKeys.BUILDING,
              sectionName: LOCATIONS_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.ZONE]
        ? [
            {
              label: getFieldName(DataKeys.ZONE),
              dataKey: DataKeys.ZONE,
              sectionName: LOCATIONS_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.LEVEL]
        ? [
            {
              label: getFieldName(DataKeys.LEVEL),
              dataKey: DataKeys.LEVEL,
              sectionName: LOCATIONS_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.AREA]
        ? [
            {
              label: getFieldName(DataKeys.AREA),
              dataKey: DataKeys.AREA,
              sectionName: LOCATIONS_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.ROUTE]
        ? [
            {
              label: getFieldName(DataKeys.ROUTE),
              dataKey: DataKeys.ROUTE,
              sectionName: LOCATIONS_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.GATE]
        ? [
            {
              label: getFieldName(DataKeys.GATE),
              dataKey: DataKeys.GATE,
              sectionName: LOCATIONS_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.OFFLOADING_EQUIPMENT]
        ? [
            {
              label: getFieldName(DataKeys.OFFLOADING_EQUIPMENT),
              dataKey: DataKeys.OFFLOADING_EQUIPMENT,
              sectionName: EQUIPMENT_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.NUMBER_OF_EQUIPMENT_PICKS]
        ? [
            {
              label: getFieldName(DataKeys.NUMBER_OF_EQUIPMENT_PICKS),
              dataKey: DataKeys.NUMBER_OF_EQUIPMENT_PICKS,
              sectionName: EQUIPMENT_SECTION_NAME,
            },
          ]
        : []),

      ...(!hiddenFields[FieldIds.MATERIALS_SECTION]
        ? [
            {
              label: Localization.translator.materials,
              dataKey: DataKeys.MATERIALS_SECTION,
              sectionName: MATERIALS_SECTION_NAME,
            },
            {
              label: Localization.translator.items,
              dataKey: DataKeys.MATERIALS_ITEMS_COUNT,
              sectionName: MATERIALS_SECTION_NAME,
            },
          ]
        : []),

      ...(!hiddenFields[DataKeys.VENDOR]
        ? [
            {
              label: getFieldName(DataKeys.VENDOR),
              dataKey: DataKeys.VENDOR,
              sectionName: VENDOR_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.TRUCK_SIZE]
        ? [
            {
              label: getFieldName(DataKeys.TRUCK_SIZE),
              dataKey: DataKeys.TRUCK_SIZE,
              sectionName: VENDOR_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.TRUCK_LICENSE_PLATE]
        ? [
            {
              label: getFieldName(DataKeys.TRUCK_LICENSE_PLATE),
              dataKey: DataKeys.TRUCK_LICENSE_PLATE,
              sectionName: VENDOR_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.DRIVER_PHONE_NUMBERS]
        ? [
            {
              label: getFieldName(DataKeys.DRIVER_PHONE_NUMBERS),
              dataKey: DataKeys.DRIVER_PHONE_NUMBERS,
              sectionName: VENDOR_SECTION_NAME,
            },
          ]
        : []),
    ]

    return columns.map(column => ({
      ...column,
      ...(this.columnsWidthState.has(column.dataKey) && {
        width: this.columnsWidthState.get(column.dataKey),
      }),
    }))
  }

  @computed
  public get statusTitle(): string {
    if (this.isSomeRowSelected) {
      return Localization.translator.xOfYSelected(
        this.selection.size,
        this.displayedCount,
      )
    }

    return Localization.translator.xDeliveriesShowing(this.displayedCount)
  }

  @computed
  public get categoryToInstancesMap(): { [categoryKey: string]: Delivery[] } {
    const map = {}

    this.filteredCollection.forEach(delivery => {
      const categoryId = delivery[this.groupingKey]
      const formattedCategoryId = this.formatCategoryId(categoryId, delivery)

      if (map[formattedCategoryId]) {
        map[formattedCategoryId].push(delivery)
      } else {
        map[formattedCategoryId] = [delivery]
      }
    })

    return map
  }

  public get activeDeliveryId(): string {
    return this.desktopDeliveryViewStore.activeDeliveryId
  }

  public get isCdDeliverySelected(): boolean {
    return this.selectedInstances.some(d => d.isFromConcreteDirect)
  }

  @computed
  public get filteredCollection() {
    const { isMyDeliveriesOnly, isMyDelivery, areCDOrdersHidden } =
      this.desktopDeliveryViewStore

    let filteredDeliveries =
      this.getFilteredCollectionExcludeFilter(excludedFilters)

    if (areCDOrdersHidden) {
      filteredDeliveries = filteredDeliveries.filter(
        d => !d.isFromConcreteDirect,
      )
    }

    if (!isMyDeliveriesOnly) {
      return filteredDeliveries
    }

    return filteredDeliveries.filter(isMyDelivery)
  }

  @computed
  public get subscriptionConfirmMessage(): string {
    const dSelectedCount = this.selection.size
    const dUnsubscribedCount = this.unsubscribedSelectedDeliveriesIds.length

    const { subscribeConfirmMassage, unsubscribeConfirmMessage } =
      Localization.translator.deliveriesListDescriptions

    if (this.isSubscribeMode) {
      return subscribeConfirmMassage(dUnsubscribedCount, dSelectedCount)
    }

    return unsubscribeConfirmMessage(dSelectedCount)
  }

  @computed
  public get subscriptionConfirmButtonText(): string {
    return this.isSubscribeMode
      ? Localization.translator.subscribe_verb
      : Localization.translator.yesUnsubscribe
  }

  @computed
  public get unsubscribedSelectedDeliveriesIds(): string[] {
    if (!this.selectedInstances.length) {
      return []
    }

    const { isEntityFollowed } = this.deliveryFollowingsStore

    return this.selectedInstances
      .filter(d => !isEntityFollowed(d.id))
      .map(d => d.id)
  }

  public get isSubscribeMode(): boolean {
    return !!this.unsubscribedSelectedDeliveriesIds.length
  }

  public get shouldSubscriptionConfirmModalShow() {
    return this.desktopDeliveryViewStore.shouldSubscriptionConfirmModalShow
  }

  @action.bound
  public handleMount() {
    this.initPostEventCallback()

    if (!this.activeDeliveryId) {
      return
    }

    if (this.activeDeliveryId !== this.initDeliveryId) {
      this.initDeliveryId = this.activeDeliveryId
      this.resetSelection()
    }

    this.scrollToInstance(this.activeDeliveryId)
    this.selection.set(this.activeDeliveryId, true)
  }

  @action.bound
  public handleUnmount() {
    this.clearPostEventCallback()

    if (this.appState.deliveriesList.viewMode === ViewModes.List) {
      return
    }

    this.initDeliveryId = this.activeDeliveryId || this.lastSelectedInstanceId
    this.selectDelivery(this.initDeliveryId)
  }

  @action.bound
  public onEvent(eventContext: EventContext) {
    const [eventType] = eventContext.event

    switch (eventType) {
      case DELETE_DELIVERIES:
        const [, deliveryId] = eventContext.event
        return this.toggleInstance(deliveryId)
      case ACTIVATE_PROJECT:
        this.resetSelection()
        this.initDeliveryId = null
    }
  }

  @action.bound
  public selectRow(deliveryId: string, shouldAddMaterial?: boolean) {
    this.resetSelection()
    this.selectDelivery(deliveryId, shouldAddMaterial)
  }

  public isDeliverySelected = (deliveryId: string): boolean => {
    return this.selection.get(deliveryId)
  }

  @action.bound
  public toggleRowCheckbox(deliveryId: string) {
    const isChecked = this.selection.get(deliveryId)
    this.toggleInstance(deliveryId)

    this.hideDeliveryDetailsIfNeed(deliveryId, isChecked)
  }

  @action.bound
  public handleChangeAssociations() {
    if (this.isSubscribeMode) {
      this.subscribe()
    } else {
      this.unsubscribe()
    }

    this.closeSubscriptionConfirmModal()
  }

  @action.bound
  public closeSubscriptionConfirmModal() {
    this.desktopDeliveryViewStore.closeSubscriptionConfirmModal()
  }

  protected get instancesInPeriodInterval() {
    return this.desktopDeliveryViewStore.currentViewDeliveries
  }

  protected sortCategories(keys: string[]): string[] {
    if (this.groupingKey === DeliveryGroupingOption[DataKeys.DATE]) {
      return keys.sort((k1, k2) => +k2 - +k1)
    }

    return super.sortCategories(keys)
  }

  protected toRows(
    deliveries: Delivery[],
    _category?: string,
    level: number = 0,
  ) {
    return deliveries.map(delivery => {
      const {
        getZoneById,
        getBuildingById,
        getRouteById,
        getGateById,
        getVehicleTypeById,
        getOffloadEquipmentsByIds,
        getLevelById,
        getAreaById,
        getCompanyById,
      } = this.desktopDeliveryViewStore

      const zone = getZoneById(delivery.zone)
      const gate = getGateById(delivery.gate)
      const route = getRouteById(delivery.route)
      const building = getBuildingById(delivery.building)
      const truck = getVehicleTypeById(delivery.truckSize)
      const eqs = getOffloadEquipmentsByIds(delivery.offloadingEquipmentIds)
      const levelAttr = getLevelById(delivery.level)
      const area = getAreaById(delivery.area)
      const company = getCompanyById(delivery.company)
      const vendor = getCompanyById(delivery.vendor)

      const data = {
        [DataKeys.ID]: delivery.id,
        [DataKeys.BOOKING_ID]: delivery,
        [DataKeys.CHECKBOX]: this.selection.get(delivery.id),
        [DataKeys.STATUS]: this.getDeliveryStatus(delivery),
        [DataKeys.DATE]: this.getRanges(delivery),
        [DataKeys.GATE]: gate || null,
        [DataKeys.ZONE]: zone || null,
        [DataKeys.BUILDING]: building || null,
        [DataKeys.LEVEL]: levelAttr || null,
        [DataKeys.AREA]: area || null,
        [DataKeys.ROUTE]: route || null,
        [DataKeys.VENDOR]: vendor?.name || null,
        [DataKeys.DURATION]: this.getDurations(delivery),
        [DataKeys.OFFLOADING_EQUIPMENT]: this.getEquipmentsLabel(eqs),
        [DataKeys.NUMBER_OF_EQUIPMENT_PICKS]:
          delivery.numberOfEquipmentPicks || NO_VALUE,
        [DataKeys.TRUCK_SIZE]: this.getVehicleLabel(
          truck,
          delivery.truckNumber,
        ),
        [DataKeys.TRUCK_LICENSE_PLATE]: delivery.truckLicensePlate,
        [DataKeys.MATERIALS_SECTION]: {
          id: delivery.id,
          onAddClick: this.selectRow,
        },
        [DataKeys.MATERIALS_ITEMS_COUNT]: delivery.materials?.length || 0,
        [DataKeys.ON_SITE_CONTACTS]: delivery.onSiteContactPersonIds || [],
        [DataKeys.COMPANY]: company?.name || null,
        [DataKeys.DRIVER_PHONE_NUMBERS]: delivery.driverPhoneNumbers || [],
      }

      return { data, level }
    })
  }

  protected getCategoryLabelById(categoryId: any): string {
    const {
      getZoneById,
      getGateById,
      getBuildingById,
      getRouteById,
      getMaterialsByIds,
      getVehicleTypeById,
      getOffloadEquipmentsByIds,
      getLevelById,
      getAreaById,
    } = this.desktopDeliveryViewStore
    const { getById } = this.projectMembersStore

    switch (this.groupingKey) {
      case DeliveryGroupingOption[FieldIds.ZONE]:
        return this.getName(getZoneById(categoryId))

      case DeliveryGroupingOption[FieldIds.ROUTE]:
        return this.getName(getRouteById(categoryId))

      case DeliveryGroupingOption[FieldIds.BUILDING]:
        return this.getName(getBuildingById(categoryId))

      case DeliveryGroupingOption[FieldIds.LEVEL]:
        return this.getName(getLevelById(categoryId))

      case DeliveryGroupingOption[FieldIds.AREA]:
        return this.getName(getAreaById(categoryId))

      case DeliveryGroupingOption[FieldIds.GATE]:
        return this.getName(getGateById(categoryId))

      case DeliveryGroupingOption[FieldIds.TRUCK_SIZE]:
        return this.getName(getVehicleTypeById(categoryId))

      case DeliveryGroupingOption[FieldIds.ON_SITE_CONTACTS]:
        const user = getById(categoryId)
        return User.getFullNameToDisplay(user, this.userProjectsStore, true)

      case DeliveryGroupingOption[FieldIds.OFFLOADING_EQUIPMENT]:
        const eqs = getOffloadEquipmentsByIds(categoryId.split(','))
        return this.getEquipmentsLabel(eqs)

      case DeliveryGroupingOption[FieldIds.MATERIAL]:
        const materials = getMaterialsByIds(categoryId.split(','))
        return this.getMaterialsLabel(materials)

      case DeliveryGroupingOption[FieldIds.DATE]:
        return this.projectDateStore.getMonthDayAndYearToDisplay(+categoryId)

      default:
        return categoryId
    }
  }

  protected formatCategoryId(categoryId: any, delivery: Delivery) {
    switch (this.groupingKey) {
      case DeliveryGroupingOption[FieldIds.STATUS]:
        return formatStatusToDisplay(categoryId, delivery.cancellationReason)
      case DeliveryGroupingOption[FieldIds.DATE]:
        return this.projectDateStore.startOfDay(categoryId).getTime().toString()
      case DeliveryGroupingOption[FieldIds.DURATION]:
        return millisecondsToTime(categoryId)
      case DeliveryGroupingOption[FieldIds.OFFLOADING_EQUIPMENT]:
      case DeliveryGroupingOption[FieldIds.MATERIAL]:
        return categoryId.join(',')

      default:
        return categoryId || EMPTY_STRING
    }
  }

  protected sortTreeObjs = (treeNodeObjs: ITreeNodeObj[]) => {
    const map = new Map<string, ITreeNodeObj>()

    treeNodeObjs.forEach(obj => map.set(obj.id, obj))

    return Array.from(map.values()).sort((a, b) => {
      const nodeA = deliveryStatusToSortIndexMap[a.id] || 0
      const nodeB = deliveryStatusToSortIndexMap[b.id] || 0
      return nodeA - nodeB
    })
  }

  // LBS bands should not be sorted by name due to entities like levels
  protected sortLBSTreeObjs = (treeNodeObjs: ITreeNodeObj[]) => {
    return treeNodeObjs
  }

  private sortDateTreeObjs = (treeNodeObjs: ITreeNodeObj[]) => {
    const map = new Map<string, ITreeNodeObj>()
    treeNodeObjs.forEach(obj => map.set(obj.id, obj))

    return Array.from(map.values()).sort(
      (a, b) => parseInt(b.id, 10) - parseInt(a.id, 10),
    )
  }

  protected compareRows = (
    { data: aRowData }: ILWFCRow,
    { data: bRowData }: ILWFCRow,
  ): number => {
    const { columnKey, order } = this.sortState

    const v1 = this.getComparableValue(aRowData, columnKey)
    const v2 = this.getComparableValue(bRowData, columnKey)

    if (typeof v1 === 'string' && typeof v2 === 'string') {
      return v1.localeCompare(v2) * order
    }

    if (typeof v1 === 'number' && typeof v2 === 'number') {
      return (v1 - v2) * order
    }

    return 0
  }

  protected searchFiltering = ({ id, codeToDisplay }: Delivery) => {
    const key = this.filter.searchKey.toLowerCase()

    if (codeToDisplay(this.companiesStore).toLowerCase().includes(key)) {
      return true
    }

    const filteredSubscribersNames = Object.keys(
      this.assigneeNameToDeliveryIdsMap,
    ).filter(name => name.toLowerCase().includes(key))

    return filteredSubscribersNames.some(name =>
      this.assigneeNameToDeliveryIdsMap[name].includes(id),
    )
  }

  protected createCategoryRow(categoryId: string, count: number): ILWFCRow {
    const categoryInstances = this.categoryToInstancesMap[categoryId]

    const total = categoryInstances.length
    const completed = categoryInstances.filter(d => d.isDone).length

    const category: ILWFCCategory = {
      categoryId,
      shortCategoryLabel: this.getCategoryLabelById(categoryId),
      categoryLabel: `${this.getCategoryLabelById(categoryId)} (${count})`,
      isChecked: categoryInstances.every(
        inst =>
          !this.canSelectInstance(inst) || this.selection.get(inst[this.idKey]),
      ),
      instancesInfo: `${completed}/${total}`,
    }

    return {
      category,
      data: {},
      height: CATEGORY_ROW_HEIGHT,
      level: 0,
    }
  }

  protected getTreeNodeObjsByBand(currentBand: string): ITreeNodeObj[] {
    const { getMonthDayAndTimeToDisplay } = this.projectDateStore
    const {
      getMaterialsByIds,
      buildingFilterOptions,
      zoneFilterOptions,
      routeFilterOptions,
      gateFilterOptions,
      equipmentFilterOptions,
      levelFilterOptions,
      areaFilterOptions,
      companyFilterOptions,
      getVehicleTypeById,
    } = this.desktopDeliveryViewStore

    switch (currentBand) {
      case SITE_BAND:
        return [{ id: SITE_BAND, name: Localization.translator.site }]

      case DeliveryGroupingOption[FieldIds.COMPANY]:
        return companyFilterOptions.map(commonAttributeMapper)

      case DeliveryGroupingOption[FieldIds.STATUS]:
        return Object.values(DeliveryStatusListView).map(status =>
          groupingCommonMapper(formatStatusToDisplay(status)),
        )

      case DeliveryGroupingOption[FieldIds.DATE]:
        return this.filteredCollection.map(d => ({
          name: getMonthDayAndTimeToDisplay(d.startDate),
          id: `${d.startDate}`,
        }))

      case DeliveryGroupingOption[FieldIds.BUILDING]:
        return buildingFilterOptions.map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.ZONE]:
        return zoneFilterOptions.map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.LEVEL]:
        return levelFilterOptions.map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.AREA]:
        return areaFilterOptions.map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.GATE]:
        return gateFilterOptions.map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.ROUTE]:
        return routeFilterOptions.map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.OFFLOADING_EQUIPMENT]:
        return equipmentFilterOptions.map(commonAttributeMapper)

      case DeliveryGroupingOption[FieldIds.ON_SITE_CONTACTS]:
        const users = this.projectMembersStore.getByIds(
          this.filteredCollection
            .map(({ onSiteContactPersonIds }) => onSiteContactPersonIds)
            .flat(),
        )

        if (this.hasUnassignedSiteContacts) {
          users.push(null)
        }

        return users.map(user => {
          const fullName = User.getFullNameToDisplay(
            user,
            this.userProjectsStore,
            true,
          )
          return { id: user?.id || UNASSIGNED, name: fullName || NO_VALUE }
        })

      case DeliveryGroupingOption[FieldIds.VENDOR]:
        return companyFilterOptions.map(commonAttributeMapper)

      case DeliveryGroupingOption[FieldIds.TRUCK_SIZE]:
        return this.filteredCollection
          .map(d => d.truckSize || NO_SPECIFIED)
          .map(truckId => {
            const vehicleTypeName = getVehicleTypeById(truckId)?.name
            return {
              name: vehicleTypeName || NO_VALUE,
              id: vehicleTypeName ? truckId : NO_SPECIFIED,
            } as ITreeNodeObj
          })

      case DeliveryGroupingOption[FieldIds.MATERIAL]:
        return this.filteredCollection
          .map(d => {
            const materials = getMaterialsByIds(d.materialIds)
            return this.getMaterialsCategory(materials)
          })
          .map(groupingCommonMapper)

      case DeliveryGroupingOption[FieldIds.DURATION]:
        return this.filteredCollection
          .map(d => millisecondsToTime(d.durationMs))
          .map(groupingCommonMapper)

      case DeliveryGroupingOption[FieldIds.CANCELATION_REASON]:
        return Object.values(CancelationReasonNames).map(reason =>
          groupingCommonMapper(reason),
        )

      case DeliveryGroupingOption[FieldIds.INSPECTION_SECTION]:
        return Object.values(InspectionStates).map(reason =>
          groupingCommonMapper(reason),
        )
    }
  }

  @computed
  protected get bandObjectMap(): {
    [bandType: string]: { [groupName: string]: Delivery[] }
  } {
    const map = {}

    const groupingOptions = Object.values(DeliveryGroupingOption).filter(
      option => option !== DeliveryGroupingOption.location,
    )
    groupingOptions.push(SITE_BAND)

    groupingOptions.forEach(band => {
      const bandMap = (map[band] = {})

      this.filteredCollection.forEach(delivery => {
        const groupNames: string[] = this.getGroupNames(delivery, band)
        if (!groupNames.length) {
          groupNames.push(UNASSIGNED)
        }

        groupNames.forEach(groupName => {
          if (!bandMap[groupName]) {
            bandMap[groupName] = []
          }

          const index = bandMap[groupName].findIndex(
            d => d.startDate > delivery.startDate,
          )

          if (index === -1) {
            bandMap[groupName].push(delivery)
          } else {
            bandMap[groupName].splice(index, 0, delivery)
          }
        })
      })
    })

    return map
  }

  @computed
  private get assigneeNameToDeliveryIdsMap() {
    const { assignedEntitiesByUserIdMap } = this.deliveryAssignmentsStore

    return Object.keys(assignedEntitiesByUserIdMap).reduce((acc, userId) => {
      const assignee = this.projectMembersStore.getById(userId)
      const assigneeName = User.getFullNameToDisplay(
        assignee,
        this.userProjectsStore,
        true,
      )

      acc[assigneeName] = assignedEntitiesByUserIdMap[userId]

      return acc
    }, {})
  }

  private getComparableValue(rowData: LWFCRowData, columnKey: string) {
    const { getDeliveryById } = this.desktopDeliveryViewStore

    const delivery = getDeliveryById(rowData[DataKeys.ID])

    switch (columnKey) {
      case DataKeys.DURATION:
      case DataKeys.DATE:
      case DataKeys.ON_SITE_CONTACTS:
        return delivery[DeliveryGroupingOption[columnKey]]

      case DataKeys.ZONE:
      case DataKeys.GATE:
      case DataKeys.ROUTE:
      case DataKeys.BUILDING:
      case DataKeys.LEVEL:
      case DataKeys.AREA:
        const attr: IDeliveryAttributeDto = rowData[columnKey]
        return (attr && attr.name) || ''

      case DataKeys.STATUS:
        return deliveryStatusToSortIndexMap[rowData[DataKeys.STATUS]]

      case DataKeys.BOOKING_ID:
        const deliveryCode = delivery.codeToDisplay(this.companiesStore)
        return deliveryCode === NO_SPECIFIED ? '' : deliveryCode

      case DataKeys.MATERIALS_SECTION:
        const materialsLabel = this.getDeliveryMaterialsLabel(delivery)
        return materialsLabel === NO_SPECIFIED ? '' : materialsLabel

      case DataKeys.MATERIALS_ITEMS_COUNT:
        return delivery.materials?.length || 0

      default:
        const value = rowData[columnKey]
        return value === NO_SPECIFIED ? '' : value
    }
  }

  @action.bound
  private selectDelivery(deliveryId: string, shouldAddMaterial?: boolean) {
    this.desktopDeliveryViewStore.activateDeliveryById(
      deliveryId,
      shouldAddMaterial,
    )
  }

  private getRanges(delivery: Delivery): IDateCell {
    const { getTimeIntervalToDisplay } = this.projectDateStore
    const { startDate, endDate, actualStartDate, actualEndDate } = delivery

    const ranges = [getTimeIntervalToDisplay(startDate, endDate)]

    if (delivery.isDone) {
      ranges.push(getTimeIntervalToDisplay(actualStartDate, actualEndDate))
    }

    return {
      dates: ranges,
      isActive: delivery.isActiveForToday(this.projectDateStore),
    }
  }

  private getDurations(delivery: Delivery): IDateCell {
    const durations = [millisecondsToTime(delivery.durationMs)]

    if (delivery.isDone) {
      if (delivery.actualDurationMs) {
        durations.push(millisecondsToTime(delivery.actualDurationMs))
      } else {
        durations.push(EMPTY_DATE_VALUE)
      }
    }

    return { dates: durations }
  }

  private getDeliveryStatus(delivery: Delivery) {
    return this.isDeliveryUpdatingOrEditing(delivery) ? null : delivery.status
  }

  private isDeliveryUpdatingOrEditing({ id }: Delivery) {
    const {
      loading,
      timestampToUpdatingDeliveriesIdsMap,
      timestampToEditingDeliveriesIdsMap,
    } = this.appState

    if (
      !loading.get(UPDATE_BULK_DELIVERIES_STATUS) &&
      !loading.get(EDIT_BULK_DELIVERIES)
    ) {
      return false
    }

    const uDeliveries = Object.values(timestampToUpdatingDeliveriesIdsMap)
    const eDeliveries = Object.values(timestampToEditingDeliveriesIdsMap)

    return (
      uDeliveries.some(arr => arr.includes(id)) ||
      eDeliveries.some(arr => arr.includes(id))
    )
  }

  private getVehicleLabel(
    vehicle: IDeliveryAttributeDto,
    count: number | string,
  ) {
    const vehicleType = this.getName(vehicle)
    return `${+count || 1} ${MULTIPLICATION} ${vehicleType}`
  }

  private getEquipmentsLabel(equipments: IDeliveryAttributeDto[]) {
    return equipments.map(eq => eq.name).join(', ') || NO_VALUE
  }

  private getDeliveryMaterialsLabel(delivery: Delivery): string {
    const materials = this.desktopDeliveryViewStore.getMaterialsByIds(
      delivery.materialIds,
    )
    const { isCSIDisabled, isCSISubCategoriesDisabled } =
      this.appState.projectMaterialOptions

    if (!isCSISubCategoriesDisabled || !isCSIDisabled) {
      return !isCSISubCategoriesDisabled
        ? this.getMaterialsLabel(materials)
        : this.getMaterialsCategory(materials)
    }

    return (
      delivery.materials
        ?.map(d => d.note)
        .filter(m => m)
        .join(', ') || NO_VALUE
    )
  }

  private getMaterialsLabel(materials: Material[]): string {
    return (
      materials
        .map(m => m.productName)
        .filter(m => m)
        .join(', ') || NO_VALUE
    )
  }

  private getMaterialsCategory(materials: Material[]): string {
    return (
      materials
        .map(m => this.materialCategoryStore.getCategoryNameById(m.categoryId))
        .filter(m => m)
        .join(', ') || NO_VALUE
    )
  }

  private hideDeliveryDetailsIfNeed(deliveryId: string, isChecked: boolean) {
    if (!this.activeDeliveryId) {
      return
    }

    if (isChecked && deliveryId === this.activeDeliveryId) {
      this.desktopDeliveryViewStore.hideDeliveryDetails()
    }
  }

  private get appState() {
    return this.eventsStore.appState
  }

  private initPostEventCallback() {
    this.clearPostEventCallback = this.eventsStore.addPostEventCallback(
      this.onEvent,
    )
  }

  private getName(item: { name: string }) {
    return item?.name || NO_VALUE
  }

  @action.bound
  private subscribe() {
    this.deliveryFollowingsStore.followEntities(
      this.unsubscribedSelectedDeliveriesIds,
    )
  }

  @action.bound
  private unsubscribe() {
    this.deliveryFollowingsStore.unfollowEntities(
      this.selectedInstances.map(d => d.id),
    )
  }

  private getGroupNames(delivery: Delivery, band: string): string[] {
    const {
      getMaterialsByIds,
      getVehicleTypeById,
      getCompanyById,
      buildingFilterOptions,
      zoneFilterOptions,
      routeFilterOptions,
      gateFilterOptions,
      equipmentFilterOptions,
      levelFilterOptions,
      areaFilterOptions,
    } = this.desktopDeliveryViewStore

    switch (band) {
      case SITE_BAND:
        return [SITE_BAND]
      case DeliveryGroupingOption[FieldIds.DATE]:
        return [`${delivery.startDate}`]
      case DeliveryGroupingOption[FieldIds.COMPANY]:
        const company = getCompanyById(delivery.company)
        return [company?.id || UNASSIGNED]
      case DeliveryGroupingOption[FieldIds.STATUS]:
        return Object.values(DeliveryStatusListView).filter(status =>
          delivery.status.includes(status),
        )

      case DeliveryGroupingOption[FieldIds.DURATION]:
        return [millisecondsToTime(delivery.durationMs)]

      case DeliveryGroupingOption[FieldIds.MATERIAL]:
        const materials = getMaterialsByIds(delivery.materialIds)
        return [this.getMaterialsCategory(materials) || UNASSIGNED]

      case DeliveryGroupingOption[FieldIds.VENDOR]:
        return [delivery.vendor || UNASSIGNED]
      case DeliveryGroupingOption[FieldIds.TRUCK_SIZE]:
        const truckSize = getVehicleTypeById(delivery.truckSize)
        return [truckSize?.id || NO_SPECIFIED]

      case DeliveryGroupingOption[FieldIds.BUILDING]:
        return buildingFilterOptions
          .filter(commonById(delivery.building))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.ZONE]:
        return zoneFilterOptions
          .filter(commonById(delivery.zone))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.LEVEL]:
        return levelFilterOptions
          .filter(commonById(delivery.level))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.AREA]:
        return areaFilterOptions
          .filter(commonById(delivery.area))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.GATE]:
        return gateFilterOptions
          .filter(commonById(delivery.gate))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.ROUTE]:
        return routeFilterOptions
          .filter(commonById(delivery.route))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.OFFLOADING_EQUIPMENT]:
        return equipmentFilterOptions
          .filter(commonByIds(delivery.offloadingEquipmentIds))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.CANCELATION_REASON]:
        const groupNames = Object.values(CancelationReasonNames).filter(
          cancellationReason =>
            delivery.cancellationReason === cancellationReason,
        )
        return groupNames.length ? groupNames : [CancelationReasonNames.Other]
      case DeliveryGroupingOption[FieldIds.INSPECTION_SECTION]:
        return Object.values(InspectionStates).filter(inspectionReason =>
          delivery.status.includes(inspectionReason),
        )
      case DeliveryGroupingOption[FieldIds.ON_SITE_CONTACTS]:
        const members = this.projectMembersStore.getByIds(
          delivery.onSiteContactPersonIds,
        )
        return members.map(member => member.id || UNASSIGNED)
    }
  }

  private sortingFunc = (
    shouldUseStatusBands: boolean,
    shouldUseLbsBands: boolean,
    shouldUseDateBands: boolean,
  ) => {
    switch (true) {
      case shouldUseStatusBands:
        return this.sortTreeObjs
      case shouldUseLbsBands:
        return this.sortLBSTreeObjs
      case shouldUseDateBands:
        return this.sortDateTreeObjs
      default:
        return undefined
    }
  }

  private get hasUnassignedSiteContacts(): boolean {
    return this.filteredCollection.some(
      ({ onSiteContactPersonIds }) => !onSiteContactPersonIds.length,
    )
  }
}
