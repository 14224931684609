import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { AutoSizer, Column, Table } from 'react-virtualized'

import { ProjectAccessType } from '~/client/graph'
import Checkbox from '~/client/src/shared/components/Checkbox'
import {
  ILWFCCategory,
  ILWFCColumn,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import { getAccountTypeTranslate } from '~/client/src/shared/constants/ProjectRoles'
import AccountPosition, {
  getAccountPositionTranslate,
} from '~/client/src/shared/enums/AccountPosition'
import {
  InviteStatusCaption,
  getInviteStatusTranslate,
} from '~/client/src/shared/enums/InviteStatusCaption'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Basemap from '~/client/src/shared/models/Basemap'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import { ITag } from '~/client/src/shared/models/Tag'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import BaseListStore from '~/client/src/shared/stores/ui/BaseList.store'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import { DataKeys } from '../../views/ProjectSetUp/components/ProjectMembersUpload/ProjectMembers/ProjectMembersList.store'
import CustomRowRender from '../../views/SimpleGanttView/components/CustomRowRender/CustomRowRender'

// localization: translated

export const DEFAULT_ROW_HEIGHT = 47
const MAX_TAG_COUNT_FOR_DISPLAY_IN_CELL = 2

interface IProps<T> {
  store: BaseListStore<T>
  autoSizerClassName: string
  fixedWidth?: number
  fixedHeight?: number
  valueRenderer?: (
    value: any,
    dataKey: string,
    data: LWFCRowData,
    recomputeGridSize?: () => void,
    level?: number,
  ) => JSX.Element
  isRowDisabled?: (data: LWFCRowData) => boolean
  renderCategoryLabel?: (label: string | Basemap) => JSX.Element
  renderMainCheckbox?: () => JSX.Element
  renderCategorySecondaryCell?: (
    dataKey: string,
    category: ILWFCCategory,
    isCollapsed?: boolean,
  ) => JSX.Element
  rowHeightGetter?: ({ index }) => number
  headerRowRenderer?: () => JSX.Element
  categoryContent?: (
    category: ILWFCCategory,
    dataKey: string,
    gridProps: any,
    data?: LWFCRowData,
    level?: number,
    recomputeTableSize?: () => void,
  ) => JSX.Element
  dataCellCustomClassName?: string
  categoryRowCustomClassName?: string
  handleCellClick?: (rowData: LWFCRowData, column?: ILWFCColumn) => void
  setTableRef?: (table: Table) => void
  shouldPreventCollapsing?: boolean
}

@observer
export default class BaseObservableTable<T> extends React.Component<IProps<T>> {
  public static renderCellWithTags(
    tagsStore: TagsStore,
    tagType: string,
    values: string[],
  ): JSX.Element {
    if (!values?.length) {
      return null
    }

    const store = tagsStore.tagStoreByTagTypeMap[tagType]

    const tags: ITag[] = values
      .map(tagId => store.getInstanceById(tagId))
      .filter(tag => !!tag)

    if (!tags.length) {
      return null
    }

    const title = tags.map(({ name }) => name).join(', ')

    const restCount = tags.length - MAX_TAG_COUNT_FOR_DISPLAY_IN_CELL
    const isSingleMode = tags.length === 1

    return (
      <div
        title={title}
        className={classList({
          'row pr5 tags-cell-container': true,
          singular: isSingleMode,
          plural: !isSingleMode,
        })}
      >
        {tags.splice(0, MAX_TAG_COUNT_FOR_DISPLAY_IN_CELL).map(tag => (
          <span key={tag.id + tag.type} className="mr4 no-grow">
            <SitemapAttributeTag
              dataObject={tag as LocationAttributeBase}
              contentContainerClassName="text-ellipsis py2 tag-text"
              shouldShowAsTag={true}
            >
              <span>{tag.name}</span>
            </SitemapAttributeTag>
          </span>
        ))}
        {restCount > 0 && `(+${restCount})`}
      </div>
    )
  }

  private table: Table = null

  public componentDidMount() {
    this.props.setTableRef?.(this.table)
  }

  public render() {
    if (
      this.props.store.isSourceCollectionEmpty &&
      !this.props.store.showCategoriesWithEmptyTable
    ) {
      return this.renderAlt()
    }

    const {
      fixedWidth,
      fixedHeight,
      autoSizerClassName,
      headerRowRenderer,
      store,
      rowHeightGetter,
    } = this.props

    const { rows, columns, scrollToRow } = store

    return (
      <AutoSizer className={autoSizerClassName}>
        {({ width, height }) => (
          <Table
            width={fixedWidth || width}
            height={fixedHeight || height}
            headerHeight={DEFAULT_ROW_HEIGHT}
            scrollToIndex={scrollToRow}
            scrollToAlignment="center"
            ref={ref => (this.table = ref)}
            rowHeight={rowHeightGetter || this.defaultRowHeightGetter}
            rowCount={rows.length}
            rowGetter={this.rowGetter}
            headerRowRenderer={
              headerRowRenderer || this.defaultHeaderRowRenderer
            }
            rowRenderer={this.rowRenderer}
          >
            {columns.map((column, index) => (
              <Column
                key={index}
                style={{ minWidth: column.width }}
                {...column}
                label={
                  (column.translatorKey &&
                    Localization.getText(column.translatorKey)) ||
                  column.label ||
                  ''
                }
                cellRenderer={this.cellRenderer}
              />
            ))}
          </Table>
        )}
      </AutoSizer>
    )
  }

  protected renderAlt(): JSX.Element {
    return (
      <div className="row x-center pa10">{this.props.store.alternateHint}</div>
    )
  }

  private defaultRowHeightGetter = ({ index }) => {
    const row = this.props.store.rows[index]
    if (!index) {
      return 0
    }
    return row?.height || DEFAULT_ROW_HEIGHT
  }

  @action.bound
  private rowGetter({ index }: { index: number }) {
    return this.props.store.rows[index]
  }

  private defaultHeaderRowRenderer = () => {
    const { renderMainCheckbox, fixedWidth, store } = this.props
    return (
      <div
        style={{ width: fixedWidth }}
        className="row header-row bb-light-cool-grey"
      >
        {store.columns.map(column => {
          const isCheckboxColumn = column.dataKey === DataKeys.CHECKBOX
          return (
            <div
              key={column.dataKey}
              style={{ maxWidth: column.width, minWidth: column.width }}
              className={classList({
                'row y-end text uppercase no-outline-container cell cell-header pb5':
                  true,
                'x-center': isCheckboxColumn,
              })}
            >
              {isCheckboxColumn
                ? renderMainCheckbox?.()
                : (column.translatorKey &&
                    Localization.getText(column.translatorKey)) ||
                  column.label ||
                  ''}
            </div>
          )
        })}
      </div>
    )
  }

  private rowRenderer = props => {
    const { index, key } = props
    const {
      isRowDisabled,
      store,
      store: { rows },
    } = this.props
    const isDisabled = isRowDisabled?.(rows[index].data)
    const customClassName = isDisabled ? 'inactive-element' : 'pointer'
    props.rowKey = key

    /* 
      !warning - this can cause problems if you do not provide height prop for row object.
      rowHeightGetter will not save you. It needs to be finished or rather removed IMHO.
      The problem is inside this component, the method is "this.top".
    */
    return (
      <CustomRowRender
        store={store}
        customClassName={customClassName}
        {...props}
      />
    )
  }

  private cellRenderer = ({ columnIndex, key, rowIndex, style }) => {
    const props = { key, style }
    const {
      store: { rows, columns },
      handleCellClick,
    } = this.props
    const column = columns[columnIndex]

    if (!rowIndex) {
      return null
    }

    const { category, data, level } = rows[rowIndex]

    return category
      ? this.renderCategoryCell(category, column, props, data, level)
      : this.renderDataCell(
          data,
          column,
          props,
          handleCellClick || this.defaultHandleCellClick,
          level,
        )
  }

  private renderCategoryCell(
    category: ILWFCCategory,
    { dataKey }: ILWFCColumn,
    gridProps: any,
    data?: LWFCRowData,
    level?: number,
  ) {
    const {
      store: { collapsedCategories },
      renderCategoryLabel,
      renderCategorySecondaryCell,
      categoryContent,
      categoryRowCustomClassName,
    } = this.props
    const { categoryId, categoryLabel, isChecked } = category

    let defaultCategoryContent = null
    if (dataKey === DataKeys.CHECKBOX) {
      const icon = collapsedCategories.get(categoryId)
        ? IconNames.CARET_RIGHT
        : IconNames.CARET_DOWN
      defaultCategoryContent = (
        <div className="row absolute-block">
          <div className="row">
            <div className="category-checkbox">
              <Checkbox
                isCentered={true}
                isChecked={isChecked}
                onClick={this.handleCategoryToggle.bind(null, category)}
              />
            </div>
            <Icon className="pointer" icon={icon} />
            {renderCategoryLabel?.(categoryLabel) || (
              <span className="no-grow ml10">{categoryLabel}</span>
            )}
          </div>
        </div>
      )
    } else {
      defaultCategoryContent = renderCategorySecondaryCell?.(
        dataKey,
        category,
        collapsedCategories.get(categoryId),
      )
    }

    const defaultClassName =
      'row text bb-light-cool-grey large bold primary-blue cell category-cell full-height no-select pointer'
    const className = categoryRowCustomClassName || defaultClassName
    return (
      <div
        {...gridProps}
        onClick={this.toggleCategory.bind(null, categoryId)}
        className={classList({
          'category-cell-name': dataKey === DataKeys.CHECKBOX,
          [className]: true,
        })}
      >
        {categoryContent?.(
          category,
          dataKey,
          gridProps,
          data,
          level,
          this.recomputeTableSize,
        ) || defaultCategoryContent}
      </div>
    )
  }

  @action.bound
  private handleCategoryToggle(
    category: ILWFCCategory,
    e: React.MouseEvent<HTMLElement>,
  ) {
    e.stopPropagation()
    this.props.store.toggleCategory(category)
  }

  @action.bound
  private toggleCategory(categoryId: string) {
    if (this.props.shouldPreventCollapsing) {
      return
    }
    const { collapsedCategories } = this.props.store
    const state = collapsedCategories.get(categoryId)
    collapsedCategories.set(categoryId, !state)
    // to force rows' height recalculation
    this.recomputeTableSize()
  }

  private recomputeTableSize = () => {
    this.table.recomputeGridSize()
  }

  private renderDataCell = (
    data: LWFCRowData,
    column: ILWFCColumn,
    gridProps: any,
    handleCellClick: (rowData: LWFCRowData, column?: ILWFCColumn) => void,
    level?: number,
  ) => {
    const { dataKey } = column
    const value = data[dataKey]

    const { valueRenderer, dataCellCustomClassName } = this.props
    const content =
      valueRenderer?.(value, dataKey, data, this.recomputeTableSize, level) ||
      this.defaultValueRenderer(value, dataKey) ||
      NO_VALUE

    return (
      <div
        {...gridProps}
        onClick={handleCellClick.bind(null, data, column)}
        className={classList({
          'row cell text large full-height bb-light-cool-grey': true,
          [dataCellCustomClassName]: true,
        })}
      >
        {content}
      </div>
    )
  }

  private defaultValueRenderer(value: string, dataKey: string) {
    if (!value) {
      return value
    }

    if (dataKey === DataKeys.ACCOUNT_POSITION) {
      return getAccountPositionTranslate(value as AccountPosition)
    }

    if (dataKey === DataKeys.ACCOUNT_TYPE) {
      return getAccountTypeTranslate(value as ProjectAccessType)
    }

    if (dataKey === DataKeys.STATUS) {
      return getInviteStatusTranslate(value as InviteStatusCaption)
    }

    return value
  }

  @action.bound
  private defaultHandleCellClick(rowData: LWFCRowData) {
    this.props.store.toggleInstance(rowData[DataKeys.ID])
  }
}
