import { action, computed } from 'mobx'

import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import {
  BasicDataKeys,
  ILWFCCategory,
  ILWFCColumn,
  ILWFCRow,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import {
  deliveryStatusToSortIndexMap,
  formatStatusToDisplay,
} from '~/client/src/shared/constants/DeliveryStatus'
import { CancelationReasonNames } from '~/client/src/shared/enums/CancelationReason'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import DeliveryGroupingOption from '~/client/src/shared/enums/DeliveryGroupingOption'
import { DeliveryStatusListView } from '~/client/src/shared/enums/DeliveryStatusListView'
import { InspectionStates } from '~/client/src/shared/enums/InspectionState'
import SortOrder from '~/client/src/shared/enums/SortOrder'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'
import Material from '~/client/src/shared/models/Material'
import User from '~/client/src/shared/models/User'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveryAssignmentsStore from '~/client/src/shared/stores/domain/DeliveryAssignments.store'
import DeliveryVehicleTypeStore from '~/client/src/shared/stores/domain/DeliveryVehicleTypes.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialStore from '~/client/src/shared/stores/domain/Materials.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'
import {
  CATEGORY_ROW_HEIGHT,
  ITreeNodeObj,
} from '~/client/src/shared/stores/ui/BaseList.store'
import BaseMultiBandListStore from '~/client/src/shared/stores/ui/BaseMultiBandList.store'
import ProjectDateStore, {
  millisecondsToTime,
} from '~/client/src/shared/stores/ui/ProjectDate.store'
import IDeliveryAttributeDto from '~/client/src/shared/types/IDeliveryAttributeDto'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import {
  commonAttributeMapper,
  commonById,
  commonByIds,
  commonIdMapper,
  groupingCommonMapper,
} from '~/client/src/shared/utils/mappers'
import {
  EMPTY_DATE_VALUE,
  EMPTY_STRING,
  MULTIPLICATION,
  NO_SPECIFIED,
  NO_VALUE,
} from '~/client/src/shared/utils/usefulStrings'
import { areArraysEqual } from '~/client/src/shared/utils/util'

import { IDateCell } from '../../../../Deliveries/components/DeliveriesList/DeliveriesList.store'
import ReportsStore from '../../../Reports.store'

enum SpecificDataKeys {
  STATUS = 'status',
  BOOKING_ID = 'booking-id',
  MATERIALS_ITEMS_COUNT = 'materials-items-count',
}

export const DataKeys = { ...BasicDataKeys, ...SpecificDataKeys, ...FieldIds }
export const BOOKING_SECTION_NAME = 'booking'
export const DURATION_SECTION_NAME = 'duration'
export const LOCATIONS_SECTION_NAME = 'locations'
export const EQUIPMENT_SECTION_NAME = 'equipment'
export const MATERIALS_SECTION_NAME = 'materials'
export const VENDOR_SECTION_NAME = 'vendor'

const SITE_BAND = 'Site'
const FILTER_KEYS = ['id']

const lbsBands: string[] = [
  SITE_BAND,
  DeliveryGroupingOption[FieldIds.BUILDING],
  DeliveryGroupingOption[FieldIds.ZONE],
  DeliveryGroupingOption[FieldIds.LEVEL],
  DeliveryGroupingOption[FieldIds.AREA],
]

const statusBands: string[] = [
  FieldIds.STATUS,
  DeliveryGroupingOption[FieldIds.CANCELATION_REASON],
  DeliveryGroupingOption[FieldIds.INSPECTION_SECTION],
]

const DEFAULT_SORT_KEY = 'booking-id'

const REPORT_LBS_GROUPING_KEYS = [
  DeliveryGroupingOption.Building,
  DeliveryGroupingOption.Zone,
  DeliveryGroupingOption.Level,
  DeliveryGroupingOption.Area,
]

enum AcceptableDeliveryFilterTypes {
  status = 'status',
  company = 'company',
}

export default class DeliveryReportListStore extends BaseMultiBandListStore<Delivery> {
  public constructor(
    private readonly state: DesktopInitialState,
    private readonly reportsStore: ReportsStore,
    private readonly companiesStore: CompaniesStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly deliveryVehicleTypeStore: DeliveryVehicleTypeStore,
    private readonly materialStore: MaterialStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly materialCategoryStore: MaterialCategoryStore,
    private readonly deliveryAssignmentsStore: DeliveryAssignmentsStore,
  ) {
    super(
      state.deliveryReportFilters,
      () => reportsStore.allDeliveries,
      FILTER_KEYS,
    )
  }

  private getFieldName = (field: string): string => {
    return this.state.getDeliveryFieldName(field)
  }

  public columnsWidthState = new Map<string, number>([
    [DataKeys.CHECKBOX, 40],
    [DataKeys.BOOKING_ID, 220],
    [DataKeys.STATUS, 120],
    [DataKeys.COMPANY, 150],
    [DataKeys.ON_SITE_CONTACTS, 200],
    [DataKeys.DATE, 365],
    [DataKeys.DURATION, 85],
    [DataKeys.OFFLOADING_EQUIPMENT, 250],
    [DataKeys.NUMBER_OF_EQUIPMENT_PICKS, 120],
    [DataKeys.MATERIALS_SECTION, 550],
    [DataKeys.MATERIALS_ITEMS_COUNT, 100],
    [DataKeys.VENDOR, 180],
    [DataKeys.TRUCK_SIZE, 170],
    [DataKeys.TRUCK_LICENSE_PLATE, 170],
    [DataKeys.DRIVER_PHONE_NUMBERS, 180],
  ])

  @computed
  public get columns(): ILWFCColumn[] {
    const {
      delivery: { hiddenFields },
    } = this.state
    const { getClientTimezoneOffsetAsString } = this.projectDateStore

    const columns = [
      {
        dataKey: DataKeys.CHECKBOX,
        sectionName: BOOKING_SECTION_NAME,
      },
      {
        label: Localization.translator.bookingId,
        dataKey: DataKeys.BOOKING_ID,
        sectionName: BOOKING_SECTION_NAME,
        isMonospace: true,
      },
      {
        label: Localization.translator.status,
        dataKey: DataKeys.STATUS,
        sectionName: BOOKING_SECTION_NAME,
      },
      ...(!hiddenFields[DataKeys.COMPANY]
        ? [
            {
              label: Localization.translator.responsibleCompany,
              dataKey: DataKeys.COMPANY,
              sectionName: BOOKING_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.ON_SITE_CONTACTS]
        ? [
            {
              label: Localization.translator.responsibleContact,
              dataKey: DataKeys.ON_SITE_CONTACTS,
              sectionName: BOOKING_SECTION_NAME,
            },
          ]
        : []),
      {
        label: Localization.translator.bookingDateEnTimeOffset(
          getClientTimezoneOffsetAsString(),
        ),
        dataKey: DataKeys.DATE,
        sectionName: BOOKING_SECTION_NAME,
        width: 365,
        isMonospace: true,
      },
      {
        label: Localization.translator.durHmm_durationShort,
        dataKey: DataKeys.DURATION,
        isMonospace: true,
        sectionName: DURATION_SECTION_NAME,
      },
      ...(!hiddenFields[DataKeys.BUILDING]
        ? [
            {
              label: this.getFieldName(DataKeys.BUILDING),
              dataKey: DataKeys.BUILDING,
              sectionName: LOCATIONS_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.ZONE]
        ? [
            {
              label: this.getFieldName(DataKeys.ZONE),
              dataKey: DataKeys.ZONE,
              sectionName: LOCATIONS_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.LEVEL]
        ? [
            {
              label: this.getFieldName(DataKeys.LEVEL),
              dataKey: DataKeys.LEVEL,
              sectionName: LOCATIONS_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.AREA]
        ? [
            {
              label: this.getFieldName(DataKeys.AREA),
              dataKey: DataKeys.AREA,
              sectionName: LOCATIONS_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.ROUTE]
        ? [
            {
              label: this.getFieldName(DataKeys.ROUTE),
              dataKey: DataKeys.ROUTE,
              sectionName: LOCATIONS_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.GATE]
        ? [
            {
              label: this.getFieldName(DataKeys.GATE),
              dataKey: DataKeys.GATE,
              sectionName: LOCATIONS_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.OFFLOADING_EQUIPMENT]
        ? [
            {
              label: this.getFieldName(DataKeys.OFFLOADING_EQUIPMENT),
              dataKey: DataKeys.OFFLOADING_EQUIPMENT,
              sectionName: EQUIPMENT_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.NUMBER_OF_EQUIPMENT_PICKS]
        ? [
            {
              label: this.getFieldName(DataKeys.NUMBER_OF_EQUIPMENT_PICKS),
              dataKey: DataKeys.NUMBER_OF_EQUIPMENT_PICKS,
              sectionName: EQUIPMENT_SECTION_NAME,
            },
          ]
        : []),

      ...(!hiddenFields[FieldIds.MATERIALS_SECTION]
        ? [
            {
              label: Localization.translator.materials,
              dataKey: DataKeys.MATERIALS_SECTION,
              sectionName: MATERIALS_SECTION_NAME,
            },
            {
              label: Localization.translator.items,
              dataKey: DataKeys.MATERIALS_ITEMS_COUNT,
              sectionName: MATERIALS_SECTION_NAME,
            },
          ]
        : []),

      ...(!hiddenFields[DataKeys.VENDOR]
        ? [
            {
              label: this.getFieldName(DataKeys.VENDOR),
              dataKey: DataKeys.VENDOR,
              sectionName: VENDOR_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.TRUCK_SIZE]
        ? [
            {
              label: this.getFieldName(DataKeys.TRUCK_SIZE),
              dataKey: DataKeys.TRUCK_SIZE,
              sectionName: VENDOR_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.TRUCK_LICENSE_PLATE]
        ? [
            {
              label: this.getFieldName(DataKeys.TRUCK_LICENSE_PLATE),
              dataKey: DataKeys.TRUCK_LICENSE_PLATE,
              sectionName: VENDOR_SECTION_NAME,
            },
          ]
        : []),
      ...(!hiddenFields[DataKeys.DRIVER_PHONE_NUMBERS]
        ? [
            {
              label: this.getFieldName(DataKeys.DRIVER_PHONE_NUMBERS),
              dataKey: DataKeys.DRIVER_PHONE_NUMBERS,
              sectionName: VENDOR_SECTION_NAME,
            },
          ]
        : []),
    ]

    return columns.map(column => ({
      ...column,
      ...(this.columnsWidthState.has(column.dataKey) && {
        width: this.columnsWidthState.get(column.dataKey),
      }),
    }))
  }

  @action.bound
  public onRowClick(rowData: LWFCRowData, columnKey: string) {
    const id = rowData[DataKeys.ID]
    switch (columnKey) {
      case DataKeys.CHECKBOX:
        this.toggleInstance(id)
        return
      default:
        const delivery = this.reportsStore.allDeliveries.find(d => d.id === id)
        this.reportsStore.showDeliveryReports(delivery)
    }
  }

  @computed
  public get rows(): ILWFCRow[] {
    const { selectedDeliveryReportBandsOption } = this.state.filters

    const shouldUseLbsBands = areArraysEqual(
      selectedDeliveryReportBandsOption.bands,
      [DeliveryGroupingOption.location],
    )

    const shouldUseStatusBands = areArraysEqual(
      selectedDeliveryReportBandsOption.bands,
      [DeliveryGroupingOption.status],
    )

    const shouldUseDateBands = areArraysEqual(
      selectedDeliveryReportBandsOption.bands,
      [DeliveryGroupingOption[FieldIds.DATE]],
    )

    const customBands =
      (shouldUseLbsBands && lbsBands) ||
      (shouldUseStatusBands && statusBands) ||
      []

    const sortingFunc = this.sortingFunc(
      shouldUseStatusBands,
      shouldUseLbsBands,
      shouldUseDateBands,
    )

    return this.toBandTreeNodeRows(
      customBands.length
        ? customBands
        : selectedDeliveryReportBandsOption.bands,
      null,
      '',
      0,
      false,
      sortingFunc,
    )
  }

  private sortingFunc = (
    shouldUseStatusBands: boolean,
    shouldUseLbsBands: boolean,
    shouldUseDateBands: boolean,
  ) => {
    switch (true) {
      case shouldUseStatusBands:
        return this.sortTreeObjs
      case shouldUseLbsBands:
        return this.sortLBSTreeObjs
      case shouldUseDateBands:
        return this.sortDateTreeObjs
      default:
        return undefined
    }
  }

  public get reportInfo() {
    const {
      filters,
      deliveryReportFilters: { fieldsMap },
      activeProject,
    } = this.state
    const { order, columnKey } = this.sortState
    const { bands } = filters.selectedDeliveryReportBandsOption
    const { startDate, endDate } = this.reportsStore

    const deliveriesGrouping = areArraysEqual(bands, [FieldIds.LOCATION])
      ? REPORT_LBS_GROUPING_KEYS
      : bands

    const deliveryFilters = this.getDeliveryFilters(fieldsMap)
    return {
      materialsUploadId: activeProject.materialsUploadId,
      projectTimeZone: this.projectDateStore.getClientTimezoneId(),
      deliveriesFrom: startDate.getTime(),
      deliveriesTo: endDate.getTime(),
      filteredIds: {
        deliveries: this.filteredCollection.map(delivery => delivery.id),
      },
      filters: deliveryFilters,
      grouping: {
        deliveries: deliveriesGrouping,
      },
      sorting: {
        deliveries: [
          {
            ascending: order !== SortOrder.DESC,
            key:
              !columnKey || columnKey === DEFAULT_SORT_KEY
                ? DEFAULT_SORT_KEY
                : DeliveryGroupingOption[columnKey],
          },
        ],
      },
    }
  }

  private getDeliveryFilters(fieldsMap: {
    [filterType: string]: UIFilterInfo
  }): { [filterType: string]: string[] } {
    const result: { [filterType: string]: string[] } = {}

    Object.entries(fieldsMap).forEach(([filterType, filter]) => {
      switch (filterType) {
        case AcceptableDeliveryFilterTypes.company:
          result[filterType] = Array.from(
            filter.selectedFilterOptions.keys(),
          ).map(id => this.companiesStore.getCompanyById(id)?.name)
          break
        case AcceptableDeliveryFilterTypes.status:
          result[filterType] = Array.from(filter.selectedFilterOptions.keys())
          break
      }
    })

    return result
  }

  protected get instancesInPeriodInterval(): Delivery[] {
    return this.reportsStore.deliveriesInPeriodInterval
  }

  protected sortTreeObjs = (treeNodeObjs: ITreeNodeObj[]) => {
    const map = new Map<string, ITreeNodeObj>()

    treeNodeObjs.forEach(obj => map.set(obj.id, obj))

    return Array.from(map.values()).sort((a, b) => {
      const nodeA = deliveryStatusToSortIndexMap[a.id] || 0
      const nodeB = deliveryStatusToSortIndexMap[b.id] || 0
      return nodeA - nodeB
    })
  }

  // LBS bands should not be sorted by name due to entities like levels
  protected sortLBSTreeObjs = (treeNodeObjs: ITreeNodeObj[]) => {
    return treeNodeObjs
  }

  private sortDateTreeObjs = (treeNodeObjs: ITreeNodeObj[]) => {
    return treeNodeObjs.sort((a, b) => parseInt(b.id, 10) - parseInt(a.id, 10))
  }

  protected compareRows = (
    { data: aRowData }: ILWFCRow,
    { data: bRowData }: ILWFCRow,
  ): number => {
    const { columnKey, order } = this.sortState

    const v1 = this.getComparableValue(aRowData, columnKey)
    const v2 = this.getComparableValue(bRowData, columnKey)

    if (typeof v1 === 'string' && typeof v2 === 'string') {
      return v1.localeCompare(v2) * order
    }

    if (typeof v1 === 'number' && typeof v2 === 'number') {
      return (v1 - v2) * order
    }

    return 0
  }

  private getComparableValue(rowData: LWFCRowData, columnKey: string) {
    const delivery = this.filteredCollection.find(
      d => d.id === rowData[DataKeys.ID],
    )

    switch (columnKey) {
      case DataKeys.DURATION:
      case DataKeys.DATE:
      case DataKeys.ON_SITE_CONTACTS:
        return delivery[DeliveryGroupingOption[columnKey]]

      case DataKeys.ZONE:
      case DataKeys.GATE:
      case DataKeys.ROUTE:
      case DataKeys.BUILDING:
      case DataKeys.LEVEL:
      case DataKeys.AREA:
        const attr: IDeliveryAttributeDto = rowData[columnKey]
        return (attr && attr.name) || ''

      case DataKeys.STATUS:
        return deliveryStatusToSortIndexMap[rowData[DataKeys.STATUS]]

      case DataKeys.BOOKING_ID:
        const deliveryCode = delivery.codeToDisplay(this.companiesStore)
        return deliveryCode === NO_SPECIFIED ? '' : deliveryCode

      case DataKeys.MATERIALS_ITEMS_COUNT:
        return delivery.materials?.length || 0

      default:
        const value = rowData[columnKey]
        return value === NO_SPECIFIED ? '' : value
    }
  }

  @computed
  private get assigneeNameToDeliveryIdsMap() {
    const { assignedEntitiesByUserIdMap } = this.deliveryAssignmentsStore

    return Object.keys(assignedEntitiesByUserIdMap).reduce((acc, userId) => {
      const assignee = this.projectMembersStore.getById(userId)
      const assigneeName = User.getFullNameToDisplay(
        assignee,
        this.userProjectsStore,
        true,
      )

      acc[assigneeName] = assignedEntitiesByUserIdMap[userId]

      return acc
    }, {})
  }

  protected searchFiltering = ({ codeToDisplay, id }: Delivery) => {
    const key = this.filter.searchKey.toLowerCase()

    if (codeToDisplay(this.companiesStore).toLowerCase().includes(key)) {
      return true
    }

    const filteredSubscribersNames = Object.keys(
      this.assigneeNameToDeliveryIdsMap,
    ).filter(name => name.toLowerCase().includes(key))

    return filteredSubscribersNames.some(name =>
      this.assigneeNameToDeliveryIdsMap[name].includes(id),
    )
  }

  @computed
  public get categoryToInstancesMap(): { [categoryKey: string]: Delivery[] } {
    const map = {}

    this.filteredCollection.forEach(delivery => {
      const categoryId = delivery[this.groupingKey]
      const formattedCategoryId = this.formatCategoryId(categoryId, delivery)

      if (map[formattedCategoryId]) {
        map[formattedCategoryId].push(delivery)
      } else {
        map[formattedCategoryId] = [delivery]
      }
    })

    return map
  }

  protected formatCategoryId(categoryId: any, delivery: Delivery) {
    switch (this.groupingKey) {
      case DeliveryGroupingOption[FieldIds.STATUS]:
        return formatStatusToDisplay(categoryId, delivery.cancellationReason)
      case DeliveryGroupingOption[FieldIds.DATE]:
        return this.projectDateStore.startOfDay(categoryId).getTime().toString()
      case DeliveryGroupingOption[FieldIds.DURATION]:
        return millisecondsToTime(categoryId)
      case DeliveryGroupingOption[FieldIds.OFFLOADING_EQUIPMENT]:
      case DeliveryGroupingOption[FieldIds.MATERIAL]:
        return categoryId.join(',')

      default:
        return categoryId || EMPTY_STRING
    }
  }

  @action.bound
  public selectRow(objectId: string) {
    this.resetSelection()
    this.selection.set(objectId, true)
  }

  protected getTreeNodeObjsByBand(currentBand: string): ITreeNodeObj[] {
    const { getMonthDayAndTimeToDisplay } = this.projectDateStore

    switch (currentBand) {
      case SITE_BAND:
        return [{ id: SITE_BAND, name: Localization.translator.site }]

      case DeliveryGroupingOption[FieldIds.COMPANY]:
        return [...this.companiesStore.allCompanies.slice(), {}].map(
          commonAttributeMapper,
        )

      case DeliveryGroupingOption[FieldIds.STATUS]:
        return Object.values(DeliveryStatusListView).map(status =>
          groupingCommonMapper(formatStatusToDisplay(status)),
        )

      case DeliveryGroupingOption[FieldIds.DATE]:
        return this.filteredCollection.map(d => ({
          name: getMonthDayAndTimeToDisplay(d.startDate),
          id: `${d.startDate}`,
        }))

      case DeliveryGroupingOption[FieldIds.BUILDING]:
        return [
          ...this.locationAttributesStore.buildingsStore.list.slice(),
          {},
        ].map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.ZONE]:
        return [
          ...this.locationAttributesStore.zonesStore.list.slice(),
          {},
        ].map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.LEVEL]:
        return [
          ...this.locationAttributesStore.levelsStore.list.slice(),
          {},
        ].map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.AREA]:
        return [
          ...this.locationAttributesStore.areasStore.list.slice(),
          {},
        ].map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.GATE]:
        return [
          ...this.locationAttributesStore.gatesStore.list.slice(),
          {},
        ].map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.ROUTE]:
        return [
          ...this.locationAttributesStore.routesStore.list.slice(),
          {},
        ].map(commonAttributeMapper)
      case DeliveryGroupingOption[FieldIds.OFFLOADING_EQUIPMENT]:
        return [
          ...this.locationAttributesStore.offloadingEquipmentsStore.list.slice(),
          {},
        ].map(commonAttributeMapper)

      case DeliveryGroupingOption[FieldIds.ON_SITE_CONTACTS]:
        const users = this.projectMembersStore.getByIds(
          this.filteredCollection
            .map(({ onSiteContactPersonIds }) => onSiteContactPersonIds)
            .flat(),
        )

        if (this.hasUnassignedSiteContacts) {
          users.push(null)
        }

        return users.map(user => {
          const fullName = User.getFullNameToDisplay(
            user,
            this.userProjectsStore,
            true,
          )
          return { id: user?.id || UNASSIGNED, name: fullName || NO_VALUE }
        })

      case DeliveryGroupingOption[FieldIds.VENDOR]:
        return [...this.companiesStore.allCompanies.slice(), {}].map(
          commonAttributeMapper,
        )

      case DeliveryGroupingOption[FieldIds.TRUCK_SIZE]:
        return this.filteredCollection
          .map(d => d.truckSize || NO_SPECIFIED)
          .map(truckId => {
            const vehicleTypeName =
              this.deliveryVehicleTypeStore.getInstanceById(truckId, true)?.name
            return {
              name: vehicleTypeName || NO_VALUE,
              id: vehicleTypeName ? truckId : NO_SPECIFIED,
            } as ITreeNodeObj
          })

      case DeliveryGroupingOption[FieldIds.MATERIAL]:
        return this.filteredCollection
          .map(d => {
            const materials = this.materialStore.getByIds(d.materialIds)
            return this.getMaterialsCategory(materials)
          })
          .map(groupingCommonMapper)

      case DeliveryGroupingOption[FieldIds.DURATION]:
        return this.filteredCollection
          .map(d => millisecondsToTime(d.durationMs))
          .map(groupingCommonMapper)

      case DeliveryGroupingOption[FieldIds.CANCELATION_REASON]:
        return Object.values(CancelationReasonNames).map(reason =>
          groupingCommonMapper(reason),
        )

      case DeliveryGroupingOption[FieldIds.INSPECTION_SECTION]:
        return Object.values(InspectionStates).map(reason =>
          groupingCommonMapper(reason),
        )
    }
  }

  @computed
  protected get bandObjectMap(): {
    [bandType: string]: { [groupName: string]: Delivery[] }
  } {
    const map = {}

    const groupingOptions = Object.values(DeliveryGroupingOption).filter(
      option => option !== DeliveryGroupingOption.location,
    )
    groupingOptions.push(SITE_BAND)

    groupingOptions.forEach(band => {
      const bandMap = (map[band] = {})

      this.filteredCollection.forEach(delivery => {
        const groupNames: string[] = this.getGroupNames(delivery, band)
        if (!groupNames.length) {
          groupNames.push(UNASSIGNED)
        }

        groupNames.forEach(groupName => {
          if (!bandMap[groupName]) {
            bandMap[groupName] = []
          }

          const index = bandMap[groupName].findIndex(
            d => d.startDate > delivery.startDate,
          )

          if (index === -1) {
            bandMap[groupName].push(delivery)
          } else {
            bandMap[groupName].splice(index, 0, delivery)
          }
        })
      })
    })

    return map
  }

  private getGroupNames(delivery: Delivery, band: string): string[] {
    switch (band) {
      case SITE_BAND:
        return [SITE_BAND]
      case DeliveryGroupingOption[FieldIds.DATE]:
        return [`${delivery.startDate}`]
      case DeliveryGroupingOption[FieldIds.COMPANY]:
        const company = this.companiesStore.getCompanyById(delivery.company)
        return [company?.id || UNASSIGNED]
      case DeliveryGroupingOption[FieldIds.STATUS]:
        return Object.values(DeliveryStatusListView).filter(status =>
          delivery.status.includes(status),
        )

      case DeliveryGroupingOption[FieldIds.DURATION]:
        return [millisecondsToTime(delivery.durationMs)]

      case DeliveryGroupingOption[FieldIds.MATERIAL]:
        const materials = this.materialStore.getByIds(delivery.materialIds)
        return [this.getMaterialsCategory(materials) || UNASSIGNED]

      case DeliveryGroupingOption[FieldIds.VENDOR]:
        return [delivery.vendor || UNASSIGNED]
      case DeliveryGroupingOption[FieldIds.TRUCK_SIZE]:
        const truckSize = this.deliveryVehicleTypeStore.getInstanceById(
          delivery.truckSize,
          true,
        )
        return [truckSize?.id || NO_SPECIFIED]

      case DeliveryGroupingOption[FieldIds.BUILDING]:
        return this.locationAttributesStore.buildingsStore.list
          .filter(commonById(delivery.building))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.ZONE]:
        return this.locationAttributesStore.zonesStore.list
          .filter(commonById(delivery.zone))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.LEVEL]:
        return this.locationAttributesStore.levelsStore.list
          .filter(commonById(delivery.level))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.AREA]:
        return this.locationAttributesStore.areasStore.list
          .filter(commonById(delivery.area))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.GATE]:
        return this.locationAttributesStore.gatesStore.list
          .filter(commonById(delivery.gate))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.ROUTE]:
        return this.locationAttributesStore.routesStore.list
          .filter(commonById(delivery.route))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.OFFLOADING_EQUIPMENT]:
        return this.locationAttributesStore.offloadingEquipmentsStore.list
          .filter(commonByIds(delivery.offloadingEquipmentIds))
          .map(commonIdMapper)
      case DeliveryGroupingOption[FieldIds.CANCELATION_REASON]:
        const groupNames = Object.values(CancelationReasonNames).filter(
          cancellationReason =>
            delivery.cancellationReason === cancellationReason,
        )
        return groupNames.length ? groupNames : [CancelationReasonNames.Other]
      case DeliveryGroupingOption[FieldIds.INSPECTION_SECTION]:
        return Object.values(InspectionStates).filter(inspectionReason =>
          delivery.status.includes(inspectionReason),
        )
      case DeliveryGroupingOption[FieldIds.ON_SITE_CONTACTS]:
        const members = this.projectMembersStore.getByIds(
          delivery.onSiteContactPersonIds,
        )
        return members.map(member => member.id || UNASSIGNED)
    }
  }

  protected toRows(
    deliveries: Delivery[],
    _category?: string,
    level: number = 0,
  ) {
    return deliveries.map(delivery => {
      const zone = this.locationAttributesStore.getById(delivery.zone)
      const gate = this.locationAttributesStore.getById(delivery.gate)
      const route = this.locationAttributesStore.getById(delivery.route)
      const building = this.locationAttributesStore.getById(delivery.building)
      const truck = this.deliveryVehicleTypeStore.byId.get(delivery.truckSize)
      const eqs =
        this.locationAttributesStore.offloadingEquipmentsStore.getByIds(
          delivery.offloadingEquipmentIds,
        )
      const levelAttr = this.locationAttributesStore.getById(delivery.level)
      const area = this.locationAttributesStore.getById(delivery.area)
      const company = this.companiesStore.getCompanyById(delivery.company)
      const vendor = this.companiesStore.getCompanyById(delivery.vendor)

      const data = {
        [DataKeys.ID]: delivery.id,
        [DataKeys.BOOKING_ID]: {
          delivery,
          code: delivery.codeToDisplay(this.companiesStore),
        },
        [DataKeys.CHECKBOX]: this.selection.get(delivery.id),
        [DataKeys.STATUS]: delivery.status,
        [DataKeys.DATE]: this.getRanges(delivery),
        [DataKeys.GATE]: gate || null,
        [DataKeys.ZONE]: zone || null,
        [DataKeys.BUILDING]: building || null,
        [DataKeys.LEVEL]: levelAttr || null,
        [DataKeys.AREA]: area || null,
        [DataKeys.ROUTE]: route || null,
        [DataKeys.VENDOR]: vendor?.name || NO_VALUE,
        [DataKeys.DURATION]: this.getDurations(delivery),
        [DataKeys.OFFLOADING_EQUIPMENT]: this.getEquipmentsLabel(eqs),
        [DataKeys.NUMBER_OF_EQUIPMENT_PICKS]:
          delivery.numberOfEquipmentPicks || NO_VALUE,
        [DataKeys.TRUCK_SIZE]: this.getVehicleLabel(
          truck,
          delivery.truckNumber,
        ),
        [DataKeys.TRUCK_LICENSE_PLATE]: delivery.truckLicensePlate,
        [DataKeys.MATERIALS_SECTION]: {
          id: delivery.id,
          onAddClick: this.selectRow,
        },
        [DataKeys.MATERIALS_ITEMS_COUNT]: delivery.materials?.length || 0,
        [DataKeys.ON_SITE_CONTACTS]: delivery.onSiteContactPersonIds || [],
        [DataKeys.COMPANY]: company?.name || null,
        [DataKeys.DRIVER_PHONE_NUMBERS]: delivery.driverPhoneNumbers || [],
      }

      return { data, level }
    })
  }

  protected createCategoryRow(categoryId: string, count: number): ILWFCRow {
    const categoryInstances = this.categoryToInstancesMap[categoryId]

    const total = categoryInstances.length
    const completed = categoryInstances.filter(d => d.isDone).length

    const category: ILWFCCategory = {
      categoryId,
      shortCategoryLabel: this.getCategoryLabelById(categoryId),
      categoryLabel: `${this.getCategoryLabelById(categoryId)} (${count})`,
      isChecked: categoryInstances.every(
        inst =>
          !this.canSelectInstance(inst) || this.selection.get(inst[this.idKey]),
      ),
      instancesInfo: `${completed}/${total}`,
    }

    return {
      category,
      data: {},
      height: CATEGORY_ROW_HEIGHT,
      level: 0,
    }
  }

  private getRanges(delivery: Delivery): IDateCell {
    const { getTimeIntervalToDisplay } = this.projectDateStore
    const { startDate, endDate, actualStartDate, actualEndDate } = delivery

    const ranges = [getTimeIntervalToDisplay(startDate, endDate)]

    if (delivery.isDone) {
      ranges.push(getTimeIntervalToDisplay(actualStartDate, actualEndDate))
    }

    return {
      dates: ranges,
      isActive: delivery.isActiveForToday(this.projectDateStore),
    }
  }

  private getVehicleLabel(
    vehicle: IDeliveryAttributeDto,
    count: number | string,
  ) {
    const vehicleType = this.getName(vehicle)
    return `${+count || 1} ${MULTIPLICATION} ${vehicleType}`
  }

  private getName(item: { name: string }) {
    return item?.name || NO_VALUE
  }

  private getEquipmentsLabel(equipments: IDeliveryAttributeDto[]) {
    return equipments.map(eq => eq.name).join(', ') || NO_VALUE
  }

  private getDurations(delivery: Delivery): IDateCell {
    const durations = [millisecondsToTime(delivery.durationMs)]

    if (delivery.isDone) {
      if (delivery.actualDurationMs) {
        durations.push(millisecondsToTime(delivery.actualDurationMs))
      } else {
        durations.push(EMPTY_DATE_VALUE)
      }
    }

    return { dates: durations }
  }

  private getMaterialsCategory(materials: Material[]): string {
    return (
      materials
        .map(m => this.materialCategoryStore.getCategoryNameById(m.categoryId))
        .filter(m => m)
        .join(', ') || NO_VALUE
    )
  }

  private get hasUnassignedSiteContacts(): boolean {
    return this.filteredCollection.some(
      ({ onSiteContactPersonIds }) => !onSiteContactPersonIds.length,
    )
  }
}
