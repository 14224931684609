import React from 'react'

import { observer } from 'mobx-react'

import Checkbox from '~/client/src/shared/components/Checkbox'
import UsernameFromUid from '~/client/src/shared/components/UsernameFromUid'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import ReportsListTable from '../components/ReportsListTable/ReportsListTable'
import ScanStationListStore, { DataKeys } from './ScanStationList.store'

interface IProps {
  store: ScanStationListStore
}

@observer
export default class ScanStationList extends React.Component<IProps> {
  public render() {
    const {
      rows,
      columns,
      columnsWidthState,
      groupingKey,
      collapsedCategories,
      toggleCategoryCollapsing,
      sortState,
      handleColumnSort,
      onRowClick,
      toggleCategory,
    } = this.props.store

    return (
      <ReportsListTable
        rows={rows}
        groupingKey={groupingKey}
        sortedColumnKey={sortState.columnKey}
        sortingOrder={sortState.order}
        columns={columns}
        columnsWidthState={columnsWidthState}
        onColumnSort={handleColumnSort}
        collapsedCategories={collapsedCategories}
        columnKeyToCellRenderer={this.columnKeyToCellRenderer}
        onCategoryCollapsingToggle={toggleCategoryCollapsing}
        onCellClick={onRowClick}
        onCategoryCheckboxToggle={toggleCategory}
      />
    )
  }

  private get columnKeyToCellRenderer() {
    return {
      [DataKeys.CHECKBOX]: value => <Checkbox isChecked={value} />,
      [DataKeys.SCANNER_NAME]: this.renderString,
      [DataKeys.USER]: this.renderUser,
      [DataKeys.COMPANY]: this.renderString,
      [DataKeys.START_TIME]: this.renderString,
      [DataKeys.QUEUE_TIME]: this.renderString,
      [DataKeys.FINISH_TIME]: this.renderString,
      [DataKeys.ELAPSED_TIME]: this.renderString,
      [DataKeys.EMPLOYEE_ID]: this.renderString,
      [DataKeys.EMAIL]: this.renderString,
      [DataKeys.PHONE_NUMBER]: this.renderString,
    }
  }

  private renderUser = (userId: string): JSX.Element => {
    return (
      <UsernameFromUid
        userId={userId}
        unknownUserLabel={Localization.translator.invalidUser}
        isClickable
      />
    )
  }

  private renderString = (value: string): JSX.Element => {
    return (
      <div title={value} className="text ellipsis">
        {value}
      </div>
    )
  }
}
