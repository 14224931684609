import { action, computed, observable } from 'mobx'

import { LocationType } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import {
  ILWFCColumn,
  ILWFCRow,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import DeliveryGroupingOption from '~/client/src/shared/enums/DeliveryGroupingOption'
import KnownTranslatorKeys from '~/client/src/shared/localization/knownTranslatorKeys'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import Sitemap from '~/client/src/shared/models/Sitemap'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import {
  DEFAULT_ID_KEY,
  ITreeNodeObj,
} from '~/client/src/shared/stores/ui/BaseList.store'
import BaseMultiBandListStore from '~/client/src/shared/stores/ui/BaseMultiBandList.store'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import { sortCaseInsensitive } from '~/client/src/shared/utils/collections'

import HierarchyNode from '../../../../../../shared/models/HierarchyNode'
import MapViewSetUpStore from '../MapViewSetUp.store'
import {
  maturixStations,
  projectOverviewMaps,
  siteLogistics,
} from './MapViewItemsSetup.store'

export enum SitemapDataKeys {
  id = 'id',
  CHECKBOX = 'checkbox',
  View = 'view',
  PublishTo = 'publishTo',
  Referencing = 'referencing',
  Tags = 'tags',
  Objects = 'objects',
  Image = 'image',
  BaseMap = 'basemap',
}

export const site = 'Site'
const SEPARATOR = '|'

export enum GroupBy {
  Basemap = 'Basemap',
  Hierarchy = 'Hierarchy',
}
const DATA_ROW_HEIGHT = 80
const FILTER_KEYS = ['name']

const attributeMapper = (option: LocationAttributeBase): ITreeNodeObj => {
  return {
    name: option.name || UNASSIGNED,
    id: option.id || UNASSIGNED,
    object: option,
  }
}

export default class SitemapViewsSetupStore extends BaseMultiBandListStore<Sitemap> {
  @observable public isSiteCollapsed: boolean = false
  @observable public isDialogOpened: boolean = false

  public readonly columns: ILWFCColumn[] = [
    {
      translatorKey: KnownTranslatorKeys.viewName,
      dataKey: SitemapDataKeys.View,
      width: 600,
    },
    {
      translatorKey: KnownTranslatorKeys.publishTo,
      dataKey: SitemapDataKeys.PublishTo,
      width: 200,
    },
    {
      translatorKey: KnownTranslatorKeys.numObjects,
      dataKey: SitemapDataKeys.Objects,
      width: 200,
    },
    {
      translatorKey: KnownTranslatorKeys.geoReferencing,
      dataKey: SitemapDataKeys.Referencing,
      width: 200,
    },
    {
      translatorKey: KnownTranslatorKeys.basemap,
      dataKey: SitemapDataKeys.BaseMap,
      width: 600,
    },
  ]

  public constructor(
    private state: DesktopInitialState,
    private readonly mapViewSetUpStore: MapViewSetUpStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly tagsStore: TagsStore,
  ) {
    super(
      state.sitemapFilters,
      () => mapViewSetUpStore.sitemapsSetupStore.allSitemaps,
      FILTER_KEYS,
      DEFAULT_ID_KEY,
    )
  }

  @computed
  public get rows(): ILWFCRow[] {
    return this.toBandTreeNodeRows()
  }

  @computed
  public get tableWidth(): number {
    const columnsWidth = this.columns.reduce((sum, column) => {
      return sum + column.width
    }, 0)

    return columnsWidth
  }

  public isAttributeAssigned = (item: MapViewItemBase): boolean => {
    if (!this.selectedSitemap) {
      return false
    }
    if (this.selectedSitemap?.isProjectOverviewMap) {
      return false
    }
    const { firstBuilding } = this.locationAttributesStore.buildingsStore
    const { whiteboard } = this.mapViewSetUpStore.mapViewItemsSetupStore
    if (!item.dataObject || !firstBuilding) {
      return false
    }
    const { type, id } = item.dataObject
    if (type === LocationType.Building && id === firstBuilding.id) {
      return !this.sitemapToLocation[whiteboard.id]
    }
  }

  @computed
  public get filteredCollection() {
    return this.getFilteredCollectionExcludeFilter().sort((a, b) =>
      a.name.localeCompare(b.name),
    )
  }

  @computed
  public get sitemapToLocation(): {
    [sitemapId: string]: LocationBase
  } {
    const map = {}

    this.filteredCollection.forEach(sitemap => {
      const location = this.allLocations.find(l =>
        l.isSitemapAssigned(sitemap.id),
      )

      const chains = location?.getHierarchyChainObjs(
        this.tagsStore.tagStoreByTagTypeMap,
      )

      map[sitemap.id] = location || null

      if (chains?.length) {
        chains.forEach(chain => {
          map[sitemap.id] = chain || null
        })
      }
    })

    return map
  }

  @action.bound
  public toggleRowCheckbox(objectId: string) {
    this.toggleInstance(objectId)
  }

  @action.bound
  public toggleIsSiteCollapsed() {
    this.isSiteCollapsed = !this.isSiteCollapsed
  }

  public isRowSelected(row: ILWFCRow) {
    const selectedInstance = this.hiddenSelectedInstancesIds[0]
    return (
      row.data?.[SitemapDataKeys.View] &&
      selectedInstance === row.data[SitemapDataKeys.id]
    )
  }

  public get selectedSitemap() {
    return (
      this.mapViewSetUpStore.sitemapsSetupStore.selectedSitemap ||
      ({} as Sitemap)
    )
  }

  public get isGoToMapActive(): boolean {
    if (
      this.hiddenSelectedInstancesIds.length === 1 &&
      this.hiddenSelectedInstancesIds[0] !== this.selectedSitemap?.id
    ) {
      return true
    }
    return false
  }

  public openSelectedSitemap() {
    const sitemap = this.mapViewSetUpStore.sitemapsSetupStore.allSitemaps.find(
      s => s.id === this.hiddenSelectedInstancesIds[0],
    )
    if (sitemap) {
      this.mapViewSetUpStore.sitemapsSetupStore.selectSitemap(sitemap)
      this.mapViewSetUpStore.sitemapsSetupStore.setLastEditedSitemap(sitemap.id)

      this.toggleDialog()
    }
  }

  public toggleDialog = () => {
    this.isDialogOpened = !this.isDialogOpened
  }

  protected formatCategoryId(categoryId: any, sitemap: Sitemap) {
    return Object.keys(this.categoryToInstancesMap).find(basemap => {
      return this.categoryToInstancesMap[basemap].includes(sitemap)
    })
  }

  @computed
  protected get categoryToInstancesMap() {
    return null
  }

  protected sortCategories(keys: string[]): string[] {
    return sortCaseInsensitive(keys).sort((a: string, b: string) => {
      return a.toLowerCase().localeCompare(b.toLowerCase())
    })
  }

  protected getTreeNodeObjsByBand(currentBand: string): ITreeNodeObj[] {
    let list: LocationBase[] = []
    switch (currentBand) {
      case site:
        return [{ id: site, name: site }]
      case projectOverviewMaps:
        return [{ id: projectOverviewMaps, name: projectOverviewMaps }]
      case DeliveryGroupingOption[FieldIds.BUILDING]:
        list = this.locationAttributesStore.buildingsStore.list.slice()
        break
      case DeliveryGroupingOption[FieldIds.ZONE]:
        list = this.locationAttributesStore.zonesStore.list.slice()
        break
      case DeliveryGroupingOption[FieldIds.LEVEL]:
        list = this.locationAttributesStore.levelsStore.list.slice()
        break
      case DeliveryGroupingOption[FieldIds.AREA]:
        list = this.locationAttributesStore.areasStore.list.slice()
        break
      case DeliveryGroupingOption[FieldIds.GATE]:
        list = this.locationAttributesStore.gatesStore.list.slice()
        break
      case DeliveryGroupingOption[FieldIds.ROUTE]:
        list = this.locationAttributesStore.routesStore.list.slice()
        break
      case DeliveryGroupingOption[FieldIds.OFFLOADING_EQUIPMENT]:
        list =
          this.locationAttributesStore.offloadingEquipmentsStore.list.slice()
        break
    }

    return list.map(attributeMapper)
  }

  protected toRows(
    sitemaps: Sitemap[],
    _category?: string,
    level: number = 0,
  ): ILWFCRow[] {
    return sitemaps.map(sitemap => {
      const { id, items } = sitemap
      const displayedItems = Object.values(items).filter(item => !item.isHidden)

      return {
        data: {
          [SitemapDataKeys.id]: id,
          [SitemapDataKeys.CHECKBOX]: this.selection.get(id),
          [SitemapDataKeys.PublishTo]: sitemap,
          [SitemapDataKeys.Referencing]: sitemap,
          [SitemapDataKeys.View]: sitemap,
          [SitemapDataKeys.Objects]: displayedItems,
          [SitemapDataKeys.BaseMap]: sitemap.basemapId,
        },
        level,
        height: DATA_ROW_HEIGHT,
      }
    })
  }

  protected getCategoryLabelById(categoryId: string): string {
    return this.getCategoryDataObject(categoryId)?.name || categoryId
  }

  // sitemaps with no LBS tag should be placed after first building OR if there're no building under 'site'
  protected toBandTreeNodeRows(): ILWFCRow[] {
    const { name: activeProjectName } = this.state.activeProject
    const rows: ILWFCRow[] = [{ data: {} }]
    const siteBand = this.createTreeNodeCategoryRow(
      site,
      activeProjectName,
      null,
      0,
      this.filteredCollection,
    )
    rows.push(siteBand)

    if (!this.collapsedCategories.get(site)) {
      this.mapViewSetUpStore.mapViewItemsSetupStore.sitemapHierarchyTree.forEach(
        node => {
          if (
            node.nodeId === projectOverviewMaps &&
            !this.collapsedCategories.get(projectOverviewMaps)
          ) {
            rows.push(...this.setNodes(node, 1, ''))
          }
          if (
            node.nodeId === siteLogistics &&
            !this.collapsedCategories.get(siteLogistics)
          ) {
            rows.push(...this.setNodes(node, 0, ''))
          }
          if (
            node.nodeId === maturixStations &&
            !this.collapsedCategories.get(maturixStations)
          ) {
            rows.push(...this.setNodes(node, 0, ''))
          }
          if (
            node.nodeId !== projectOverviewMaps &&
            node.nodeId !== siteLogistics &&
            node.nodeId !== maturixStations
          ) {
            rows.push(...this.setNodes(node, 1, ''))
          }
        },
      )
    }

    return rows
  }

  // as we have 7 different types in sitemap lbs structure it would be more performant to traverse through already created hierarchy
  private setNodes(node: HierarchyNode, level: number, parentName?: string) {
    const dataObject: LocationBase = node.item?.dataObject
    const locationCode: string = dataObject
      ? `${dataObject.id}${SEPARATOR}${dataObject.type}`
      : node.nodeId
    const treeNodeObjs: Sitemap[] = this.locationToSitemaps[locationCode] || []

    const categoryId: string = parentName + locationCode
    const isExpanded: boolean = !this.collapsedCategories.get(categoryId)

    const rows: ILWFCRow[] = []
    const children: ILWFCRow[] = []
    let bandItems: ILWFCRow[] = []

    if (node.hasChildren) {
      node.children.forEach(child => {
        children.push(...this.setNodes(child, level + 1, categoryId))
      })
    }

    if (treeNodeObjs?.length || children.length > 0) {
      if (dataObject) {
        const childrenData: Sitemap[] = children
          .filter(child => !child.category)
          .map(child => child.data[SitemapDataKeys.View])

        const band = this.createTreeNodeCategoryRow(
          categoryId,
          dataObject.name,
          dataObject,
          level,
          [...treeNodeObjs, ...childrenData],
        )
        rows.push(band)
      }

      if (treeNodeObjs?.length) {
        bandItems = this.toRows(
          treeNodeObjs,
          null,
          dataObject ? level + 1 : level,
        )
      }

      if (isExpanded) {
        rows.push(...children)
        rows.push(...bandItems)
      }
    }

    return rows
  }

  private getCategoryDataObject = (categoryId: string) => {
    const [instanceId, instandeType] = categoryId.split(SEPARATOR)
    let store
    switch (instandeType) {
      case LocationType.Area:
        store = this.locationAttributesStore.areasStore
        break
      case LocationType.Building:
        store = this.locationAttributesStore.buildingsStore
        break
      case LocationType.OffloadingEquipment:
        store = this.locationAttributesStore.offloadingEquipmentsStore
        break
      case LocationType.Gate:
        store = this.locationAttributesStore.gatesStore
        break
      case LocationType.Level:
        store = this.locationAttributesStore.levelsStore
        break
      case LocationType.Route:
        store = this.locationAttributesStore.routesStore
        break
      case LocationType.Zone:
        store = this.locationAttributesStore.zonesStore
        break
      default:
        return {}
    }

    return store.byId.get(instanceId)
  }

  @computed
  private get allLocations() {
    return [
      ...this.locationAttributesStore.buildingsStore.list.filter(
        b => b.assignedSitemaps?.length,
      ),
      ...this.locationAttributesStore.zonesStore.list.filter(
        b => b.assignedSitemaps?.length,
      ),
      ...this.locationAttributesStore.gatesStore.list.filter(
        b => b.assignedSitemaps?.length,
      ),
      ...this.locationAttributesStore.routesStore.list.filter(
        b => b.assignedSitemaps?.length,
      ),
      ...this.locationAttributesStore.offloadingEquipmentsStore.list.filter(
        b => b.assignedSitemaps?.length,
      ),
      ...this.locationAttributesStore.levelsStore.list.filter(
        b => b.assignedSitemaps?.length,
      ),
      ...this.locationAttributesStore.areasStore.list.filter(
        b => b.assignedSitemaps?.length,
      ),
      ...this.locationAttributesStore.stagingsStore.list.filter(
        b => b.assignedSitemaps?.length,
      ),
      ...this.locationAttributesStore.interiorDoorsStore.list.filter(
        b => b.assignedSitemaps?.length,
      ),
      ...this.locationAttributesStore.interiorPathsStore.list.filter(
        b => b.assignedSitemaps?.length,
      ),
    ]
  }

  @computed
  private get locationToSitemaps(): { [locationCode: string]: Sitemap[] } {
    const map = {
      [projectOverviewMaps]: [],
      [siteLogistics]: [],
      [maturixStations]: [],
    }

    this.filteredCollection.forEach(sitemap => {
      if (sitemap.isProjectOverviewMap) {
        map[projectOverviewMaps].push(sitemap)
        return
      }
      const location =
        this.allLocations.find(l => l.isSitemapAssigned(sitemap.id)) ||
        this.locationAttributesStore.buildingsStore.firstBuilding
      if (location) {
        const locationCode = `${location.id}${SEPARATOR}${location.type}`
        if (map[locationCode]) {
          map[locationCode].push(sitemap)
        } else {
          map[locationCode] = [sitemap]
        }
      } else {
        map[siteLogistics].push(sitemap)
      }
    })

    return map
  }
}
